import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProjects } from "../../../../redux/actions/legalsActions";
import { numberFormat } from "../../../../utils/utils";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";

const ProjectList = props => {
  const [state, setState] = useState({
    projects: {},
    claimants: {},
    tvcs: {},
    loadingProjects: true,
  });

  useEffect(() => {
    triggerLoadUsers();

    const userLoader = setInterval(triggerLoadUsers, 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, [props.match.params.status]);

  const triggerLoadUsers = () => {
    setState(prevState => {
      return {
        ...prevState,
        loadingProjects: true,
      };
    });

    getProjects("all", props.match.params.status)
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            projects: res.data.projects,
            claimants: res.data.claimants,
            tvcs: res.data.tvcs,
            loadingProjects: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const { projects, loadingProjects } = state;

  const columns = [
    {
      dataField: "projectName",
      text: "Project Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "projectURL",
      text: "Project URL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   classes: "capitalize",
    //   filter: textFilter({ placeholder: "Search..." }),
    // },
    {
      dataField: "tvc",
      text: "TVC (USD)",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
      classes: "text-right",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "claimants",
      text: "Claimants",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
      classes: "text-right",
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "pve",
      text: "PVE (USD)",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
      classes: "text-right",
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "timestamp",
      text: "Date Submitted",
      sort: true,
    },
    {
      dataField: "timeApproved",
      text: "Date Approved",
      sort: true,
    },
    {
      dataField: "dashboard",
      text: "",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeApproved",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  let data = [];
  if (Object.keys(projects).length > 0) {
    for (let i = 0; i < projects.length; i++) {
      // Some data formatting before displaying
      if (Number.isInteger(projects[i].timestamp)) {
        projects[i].timestamp = moment(projects[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }
      if (Number.isInteger(projects[i].timeApproved)) {
        projects[i].timeApproved = moment(projects[i].timeApproved * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      projects[i].tvc = state.tvcs[projects[i]._id]
        ? numberFormat(state.tvcs[projects[i]._id], 2, ".", "")
        : 0.0;

      projects[i].claimants = state.claimants[projects[i]._id]
        ? state.claimants[projects[i]._id].length
        : 0;

      projects[i].pve = projects[i].pve ? projects[i].pve : "-";

      projects[i].dashboard = (
        <Button
          as={Link}
          to={`/project/${projects[i]._id}`}
          className="blueButton mt-0 text-white font-weight-normal"
        >
          Project Dashboard
        </Button>
      );

      data.push(projects[i]);
    }
  }

  // const rowStyle = (row, rowIndex) => {
  //   let style = {};
  //
  //   if (row.status === "approved") {
  //     style = { color: "#f00" };
  //   }
  //
  //   return style;
  // };

  const pageTitle = {
    available: "Available",
    reserved: "Reserved",
    inprocess: "In Process",
    resolved: "Resolved",
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              {pageTitle[props.match.params.status]
                ? pageTitle[props.match.params.status]
                : "N/A"}{" "}
              projects {loadingProjects ? <Spinner animation="border" /> : null}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              filter={filterFactory()}
              pagination={pagination}
              // rowStyle={rowStyle}
              bordered={false}
              headerClasses="tableHeaderClass"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProjectList;
