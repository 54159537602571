import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getClaims } from "../../../redux/actions/legalsActions";
// import { cutLongText } from "../../../utils/utils";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import moment from "moment";
import { claimTypes, countryList } from "../../../config/constants";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const Claims = props => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [projects, setProjects] = useState(null);
  const [claims, setClaims] = useState(null);

  useEffect(() => {
    getClaims("all", "all", props.match.params.status)
      .then(res => {
        if (res.data.success === false) {
          NotificationManager.error(res.data.error, "Error", 5000);
        } else {
          setClaims(res.data.claims);
          setProjects(res.data.projectList);
          setUsers(res.data.userList);
        }

        setLoading(false);
      })
      .catch(err => console.error(err));
  }, [props.match.params.status]);

  const columns = [
    {
      dataField: "fullName",
      text: "User Full Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "projectName",
      text: "Project Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "fraudTypeText",
      text: "Fraud type",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "projectURL",
      text: "Project URL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    // {
    //   dataField: "projectDescriptionCut",
    //   text: "Project description",
    //   sort: true,
    //   classes: "breakWord",
    //   filter: textFilter({ placeholder: "Search..." }),
    // },
    // {
    //   dataField: "scamDescriptionCut",
    //   text: "Scam description",
    //   sort: true,
    //   classes: "breakWord",
    //   filter: textFilter({ placeholder: "Search..." }),
    // },
    {
      dataField: "country",
      text: "Country of project origin",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "Date Created",
      sort: true,
      classes: "breakWord",
    },
  ];
  if (props.match.params.status === "submitted") {
    columns.push({
      dataField: "timeSubmitted",
      text: "Date Submitted",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "returned") {
    columns.push({
      dataField: "timeReturned",
      text: "Date Returned",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "approved") {
    columns.push({
      dataField: "timeApproved",
      text: "Date Approved",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "pending") {
    columns.push({
      dataField: "timePending",
      text: "Date Pending",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "rejected") {
    columns.push({
      dataField: "timeRejected",
      text: "Date Rejected",
      sort: true,
      classes: "breakWord",
    });
  }
  columns.push({
    dataField: "action",
    text: "",
    sort: false,
  });

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  let data = [];
  if (!!claims) {
    Object.keys(claims).map((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (users && users[claims[keyName].userId]) {
        claims[keyName].fullName = `${users[claims[keyName].userId].name} ${
          users[claims[keyName].userId].surname
        }`;
        claims[keyName].email = users[claims[keyName].userId].email;

        claims[keyName].projectName = projects[claims[keyName].projectId]
          ? projects[claims[keyName].projectId].projectName
          : "N/A";

        claims[keyName].fraudTypeText = claimTypes[claims[keyName].fraudType];

        claims[keyName].projectURL = projects[claims[keyName].projectId]
          ? projects[claims[keyName].projectId].projectURL
          : "N/A";

        // Cut long descriptions
        // claims[keyName].projectDescriptionCut = projects[
        //   claims[keyName].projectId
        // ]
        //   ? cutLongText(
        //       projects[claims[keyName].projectId].projectDescription,
        //       30
        //     )
        //   : "N/A";
        // claims[keyName].scamDescriptionCut = cutLongText(
        //   claims[keyName].scamDescription,
        //   30
        // );

        claims[keyName].country = projects[claims[keyName].projectId]
          ? countryList[projects[claims[keyName].projectId].country]
          : "N/A";

        // Format dates
        if (Number.isInteger(claims[keyName].timestamp)) {
          claims[keyName].timestamp = moment(claims[keyName].timestamp * 1000)
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        // prettier-ignore
        if (props.match.params.status === "submitted" && Number.isInteger(claims[keyName].timeSubmitted)) {
          claims[keyName].timeSubmitted = moment(claims[keyName].timeSubmitted * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
        }
        // prettier-ignore
        if (props.match.params.status === "returned" && Number.isInteger(claims[keyName].timeReturned)) {
          claims[keyName].timeReturned = moment(claims[keyName].timeReturned * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
        }
        // prettier-ignore
        if (props.match.params.status === "approved" && Number.isInteger(claims[keyName].timeApproved)) {
          claims[keyName].timeApproved = moment(claims[keyName].timeApproved * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
        }
        // prettier-ignore
        if (props.match.params.status === "pending" && Number.isInteger(claims[keyName].timePending)) {
          claims[keyName].timePending = moment(claims[keyName].timePending * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
        }
        // prettier-ignore
        if (props.match.params.status === "rejected" && Number.isInteger(claims[keyName].timeRejected)) {
          claims[keyName].timeRejected = moment(claims[keyName].timeRejected * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
        }

        claims[keyName].action = (
          <Button
            as={Link}
            to={`/claim/edit/${claims[keyName]._id}`}
            className="orangeButton orangeButtonSmaller py-0 m-0"
          >
            VIEW
          </Button>
        );

        data.push(claims[keyName]);
      }
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              {props.match.params.status} Claims{" "}
              {loading ? <Spinner animation="border" /> : null}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              filter={filterFactory()}
              hover={true}
              bordered={false}
              headerClasses="tableHeaderClass"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

Claims.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Claims);
