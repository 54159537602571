import { SET_MENU_COUNTERS } from "../actions/types";

const initialState = {
  newRequests: {},
};

export default function (state = initialState, action) {
  if (action.type === SET_MENU_COUNTERS) {
    return {
      ...state,
      newRequests: action.payload,
    };
  } else {
    return state;
  }
}
