import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newPassword } from "../../../redux/actions/authActions";
import { Col, Form, Button, Spinner, Card } from "react-bootstrap";
import { encryptData } from "../../../utils/utils";
import { isMobileOnly } from "react-device-detect";
import classes from "../Login/Login.module.css";
import logo from "../../../assets/images/FDB_logo_new.svg";
import { FiAlertTriangle } from "react-icons/fi";

const ResetPassword = props => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.href = "/";
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
    }
  }, [props]);

  const handleChange = (e, target) => {
    if (target === "password") setPassword(e.target.value);
    if (target === "confirm_password") setConfirmPassword(e.target.value);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      if (password === confirmPassword) {
        if (password.length >= 6) {
          setDisableBtn(true);
          props.newPassword(props.match.params.id, encryptData(password));
        } else alert("Password MUST contain at least 6 characters!");
      } else alert("Passwords MUST match!");
    }
  };

  return (
    <div
      className="h-100 d-flex align-items-center justify-content-center"
      style={
        !isMobileOnly ? { backgroundColor: "var(--color-light-gray5)" } : null
      }
    >
      <Card className={[classes.card, "px-md-5"].join(" ")}>
        <Card.Body>
          <div style={{ height: "105px" }}>
            <img
              src={logo}
              className={classes.loginLogo}
              alt="icifdb.org"
              onClick={() => (window.location.href = "/")}
            />
          </div>

          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  onChange={e => handleChange(e, "password")}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>
                  Confirm password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="confirm_password"
                  required
                  onChange={e => handleChange(e, "confirm_password")}
                />
                <Form.Text style={{ color: "red" }}>
                  {errors.confirm_password}
                  {errors.passwordincorrect}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Text className="text-left pb-4">
              {errors.server || errors.password ? (
                <FiAlertTriangle className={classes.warningIcon} />
              ) : null}
              {errors.server}
              {errors.password}
            </Form.Text>

            <Button
              type="submit"
              className="blueButton mt-0 mb-4 float-left"
              onClick={handleSubmit}
              disabled={disableBtn}
            >
              {disableBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  RESET
                </>
              ) : (
                "RESET"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  newPassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { newPassword })(ResetPassword);
