import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/FDB_logo_new.svg";
import SidebarCounter from "../SidebarCounter/SidebarCounter";

const SidebarAdmin = props => {
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, []);

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
  };

  return (
    <>
      <img src={logo} className="sidebarLogo sidebarLogoAdmin" alt="FraudDB" />

      <div className="sidebarUser">
        <div className="menuItemTitle">CLIENT MANAGEMENT</div>
        <Button
          as={Link}
          to="/users"
          onClick={() => handleMenuItemClicked("/users")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users") ? "active" : null,
          ]}
        >
          Approved Accounts
        </Button>

        <div className="menuItemTitle">CLAIMS</div>
        {/*<Button*/}
        {/*  as={Link}*/}
        {/*  to="/claims/new"*/}
        {/*  onClick={() => handleMenuItemClicked("/claims/new")}*/}
        {/*  className={[*/}
        {/*    "menuItem py-2",*/}
        {/*    activeItem === "/claims/new" ? "active" : null,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  New Claims*/}
        {/*  {props.global.newRequests.newClaims > 0 ? (*/}
        {/*    <SidebarCounter number={props.global.newRequests.newClaims} />*/}
        {/*  ) : null}*/}
        {/*</Button>*/}
        <Button
          as={Link}
          to="/claims/approved"
          onClick={() => handleMenuItemClicked("/claims/approved")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/approved" ? "active" : null,
          ]}
        >
          Approved Claims
          {props.global.newRequests.approvedClaims > 0 ? (
            <SidebarCounter number={props.global.newRequests.approvedClaims} />
          ) : null}
        </Button>
        {/*<Button*/}
        {/*  as={Link}*/}
        {/*  to="/claims/pending"*/}
        {/*  onClick={() => handleMenuItemClicked("/claims/pending")}*/}
        {/*  className={[*/}
        {/*    "menuItem py-2",*/}
        {/*    activeItem === "/claims/pending" ? "active" : null,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  Pending Claims*/}
        {/*  {props.global.newRequests.pendingClaims > 0 ? (*/}
        {/*    <SidebarCounter number={props.global.newRequests.pendingClaims} />*/}
        {/*  ) : null}*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  as={Link}*/}
        {/*  to="/claims/rejected"*/}
        {/*  onClick={() => handleMenuItemClicked("/claims/rejected")}*/}
        {/*  className={[*/}
        {/*    "menuItem py-2",*/}
        {/*    activeItem === "/claims/rejected" ? "active" : null,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  Rejected Claims*/}
        {/*</Button>*/}

        <div className="menuItemTitle">PROJECTS</div>
        {/*<Button*/}
        {/*  as={Link}*/}
        {/*  to="/projects/new"*/}
        {/*  onClick={() => handleMenuItemClicked("/projects/new")}*/}
        {/*  className={[*/}
        {/*    "menuItem py-2",*/}
        {/*    activeItem === "/projects/new" ? "active" : null,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  All New Projects*/}
        {/*  {props.global.newRequests.newProjects > 0 ? (*/}
        {/*    <SidebarCounter number={props.global.newRequests.newProjects} />*/}
        {/*  ) : null}*/}
        {/*</Button>*/}
        <Button
          as={Link}
          to="/projects/available"
          onClick={() => handleMenuItemClicked("/projects/available")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/available" ? "active" : null,
          ]}
        >
          Available Projects
          {props.global.newRequests.availableProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.availableProjects}
            />
          ) : null}
        </Button>
        {/*<Button*/}
        {/*  as={Link}*/}
        {/*  to="/projects/rejected"*/}
        {/*  onClick={() => handleMenuItemClicked("/projects/rejected")}*/}
        {/*  className={[*/}
        {/*    "menuItem py-2",*/}
        {/*    activeItem === "/projects/rejected" ? "active" : null,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  All Rejected Projects*/}
        {/*</Button>*/}
        <br />
        <Button
          as={Link}
          to="/projects/reserved"
          onClick={() => handleMenuItemClicked("/projects/reserved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/reserved" ? "active" : null,
          ]}
        >
          Our Reserved Projects
          {props.global.newRequests.reservedProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.reservedProjects}
            />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/inprocess"
          onClick={() => handleMenuItemClicked("/projects/inprocess")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/inprocess" ? "active" : null,
          ]}
        >
          Our Projects in Process
          {props.global.newRequests.inProcessProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.inProcessProjects}
            />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/resolved"
          onClick={() => handleMenuItemClicked("/projects/resolved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/resolved" ? "active" : null,
          ]}
        >
          Our Resolved Projects
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps)(SidebarAdmin);
