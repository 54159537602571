import React from "react";
// import IconNewClient from "../../../../assets/images/icon-new-client.svg";
// import IconPending from "../../../../assets/images/icon-pending.svg";
// import IconRejected from "../../../../assets/images/icon-rejected.svg";
// import IconApproved from "../../../../assets/images/icon-approved.svg";
import moment from "moment";

const ProjectBar = props => (
  <ul className="d-flex justify-content-between list-unstyled highlightBar">
    <li>
      <small className="highlightBarCaption">PROJECT NAME</small>
      {props.projectData.projectName}
    </li>
    <li>
      <small className="highlightBarCaption">URL</small>
      {props.projectData.projectURL}
    </li>
    <li>
      <div className="highlightBarCaption">STATUS</div>
      <span style={{ textTransform: "uppercase" }}>
        {props.projectData.status}
      </span>
    </li>
    <li>
      <small className="highlightBarCaption">DATE</small>
      {moment(props.projectData.timestamp * 1000).format("YYYY/MM/DD HH:mm:ss")}
    </li>
  </ul>
);

export default ProjectBar;
