import axios from "axios";
import { GET_ERRORS, GET_SUCCESS, SET_MENU_COUNTERS } from "./types";
import { API_URL } from "../../config/config";

// Get admins
export const getAdmins = () =>
  axios.get(API_URL + "/api/legals/users/getAdmins");

// Get user
export const getUser = id =>
  axios.get(`${API_URL}/api/legals/users/${id}/profile`);

// Get all users
export const getUsers = (status, limit) =>
  axios.get(API_URL + "/api/legals/users/list/" + limit);

// Get user logs
export const getUserLogs = id =>
  axios.get(`${API_URL}/api/legals/users/${id}/logs`);

// Add user log
export const addLog = logData =>
  axios.post(API_URL + "/api/legals/users/addLog", logData);

// Get Projects
export const getProjects = (userId, status) =>
  axios.get(`${API_URL}/api/legals/projects/getProjects/${userId}/${status}`);

// Get Project data
export const getProject = id =>
  axios.get(`${API_URL}/api/legals/projects/${id}/getProject`);

// Get Project logs
export const getProjectLogs = id =>
  axios.get(`${API_URL}/api/legals/projects/${id}/getProjectLogs`);

// Get Project updates
export const getProjectUpdates = id =>
  axios.get(`${API_URL}/api/projects/${id}/getProjectUpdates`);

// Add project update
export const addProjectUpdate = update =>
  axios.post(API_URL + "/api/legals/projects/addProjectUpdate", update);

// Get Client Claimants
export const getClaimants = projectId =>
  axios.get(`${API_URL}/api/legals/claims/${projectId}/getClaimants`);

// Get sidebar counts
export const getSidebarCounts = () =>
  axios.get(API_URL + "/api/legals/claims/getSidebarCounts/");

// Set new users
export const setMenuCounters = counters => ({
  type: SET_MENU_COUNTERS,
  payload: counters,
});

// Get claims
export const getClaims = (userId, projectId, status) =>
  axios.get(
    `${API_URL}/api/legals/claims/getClaims/${userId}/${projectId}/${status}`
  );

// Get claim
export const getClaim = claimId =>
  axios.get(`${API_URL}/api/legals/claims/${claimId}/getClaim`);

// Claim project
export const claimProject = projectId =>
  axios.post(API_URL + "/api/legals/projects/claimProject", { projectId });
