import React from "react";
import { countryList, countryDialCodes } from "../../../../config/constants";
import IconNew from "../../../../assets/images/icon-new.svg";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import moment from "moment";

const ProfileBar = props => {
  let userStatusIcon;
  let statusText;

  switch (props.userData.status) {
    case "pending":
      userStatusIcon = IconPending;
      statusText = "Pending";
      break;
    case "rejected":
      userStatusIcon = IconRejected;
      statusText = "Rejected";
      break;
    default:
      userStatusIcon = IconApproved;
      statusText = "Approved";
  }

  return (
    <ul className="d-flex justify-content-between list-unstyled highlightBar">
      <li>
        <span className="statusFlag statusFlagBigger">
          <img src={userStatusIcon} alt="" /> {statusText}
        </span>
      </li>
      <li>
        <small className="highlightBarCaption">CLIENT NAME</small>
        {props.userData.name} {props.userData.surname}
      </li>
      {/*<li>*/}
      {/*  <small className="highlightBarCaption">APPLICATION TYPE</small>*/}
      {/*  {props.userData.accountType}*/}
      {/*</li>*/}
      <li>
        <small className="highlightBarCaption">TIME OF REGISTRATION</small>
        {moment(props.userData.timestamp * 1000).format("YYYY/MM/DD HH:mm:ss")}
      </li>
      <li>
        <small className="highlightBarCaption">COUNTRY OF RESIDENCE</small>
        {countryList[props.userData.country]}
      </li>
      <li>
        <small className="highlightBarCaption">AGENT CODE</small>
        {props.userData.agentCode}
      </li>
      <li>
        <small className="highlightBarCaption">EMAIL</small>
        {props.userData.email}
      </li>
      <li>
        <small className="highlightBarCaption">PHONE NUMBER</small>(
        {countryDialCodes[props.userData.country]}) {props.userData.mobilePhone}
      </li>
    </ul>
  );
};

export default ProfileBar;
