import React, { useState } from "react";
import { claimProject } from "../../redux/actions/legalsActions";
import { Button, Modal } from "react-bootstrap";
import projectCollabTermsConditions from "../../assets/documents/dummy.pdf";
import IconApproved from "../../assets/images/icon-approved.svg";
import IconRejected from "../../assets/images/icon-rejected.svg";
import { NotificationManager } from "react-notifications";

const ClaimProject = props => {
  const [disableBtn, setDisableBtn] = useState(false);

  const handleClaimProject = () => {
    setDisableBtn(true);

    claimProject(props.projectId)
      .then(res => {
        if (res.data.success) {
          createNotification("success");
        } else {
          createNotification("error", res.data.error);
        }
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(err => console.log(err));
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully claimed the project.",
          "Claim Project",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  return (
    <Modal show={true} onHide={props.closeModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Take this Project</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          By accepting this project I am accepting{" "}
          <Button
            variant="link"
            className="orangeLink"
            href={projectCollabTermsConditions}
            target="_blank"
          >
            Project Collaboration Terms and Conditions
          </Button>
          .
        </p>

        <div className="d-flex justify-content-between pt-5">
          <Button
            className="statusFlag statusFlagMedium border-0 fontWeight500 mx-4"
            disabled={disableBtn}
            onClick={handleClaimProject}
          >
            <img src={IconApproved} alt="" /> Agree
          </Button>

          <Button
            className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
            disabled={disableBtn}
            onClick={props.closeModal}
          >
            <img src={IconRejected} alt="" /> Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimProject;
