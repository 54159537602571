import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const Addresses = props => {
  let { addresses } = props;

  const columns = [
    {
      dataField: "cryptocurrency",
      text: "Cryptocurrency",
      sort: true,
    },
    {
      dataField: "myAddress",
      text: "My address",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "recipientAddress",
      text: "Recipient address",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "amount",
      text: "Amount sent",
      sort: true,
      classes: "text-right",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={addresses}
      columns={columns}
      hover={true}
      bordered={false}
      headerClasses="tableHeaderClass"
    />
  );
};

export default Addresses;
