import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from "../../../redux/actions/authActions";
import { encryptData } from "../../../utils/utils";
import { Col, Form, Button, Spinner, Card } from "react-bootstrap";
import { userRoles } from "../../../config/constants";
import classes from "./Login.module.css";
import { FiAlertTriangle } from "react-icons/fi";
import logo from "../../../assets/images/FDB_logo_new.svg";
import { isMobileOnly } from "react-device-detect";

class Login extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    disableLoginBtn: false,
  };

  componentDidMount() {
    if (
      this.props.auth.isAuthenticated &&
      userRoles[this.props.auth.user.role] === userRoles["LEGAL"]
    ) {
      window.location.href = "/claims/approved";
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (
      newProps.auth.isAuthenticated &&
      userRoles[newProps.auth.user.role] === userRoles["LEGAL"]
    ) {
      window.location.href = "/claims/approved";
    }

    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableLoginBtn: false,
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ errors: {} });

    if (form.checkValidity()) {
      this.setState({ disableLoginBtn: true });

      const userData = {
        email: this.state.email,
        password: encryptData(this.state.password),
      };

      this.props.loginUser(userData);
    }
  };

  render() {
    const { errors, disableLoginBtn } = this.state;

    return !this.props.auth.isAuthenticated ? (
      <div
        className="h-100 d-flex align-items-center justify-content-center"
        style={
          !isMobileOnly ? { backgroundColor: "var(--color-light-gray5)" } : null
        }
      >
        <Card className={[classes.card, "px-md-5"].join(" ")}>
          <Card.Body>
            <div style={{ height: "105px" }}>
              <img
                src={logo}
                className={classes.loginLogo}
                alt="FraudDB"
                onClick={() => (window.location.href = "/")}
              />
            </div>

            <Form onSubmit={e => this.handleSubmit(e)}>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className={classes.label}>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className={classes.label}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password}
                    {errors.passwordincorrect}
                  </Form.Text>

                  <Button
                    variant="link"
                    as={Link}
                    to="/forgot-password"
                    className={classes.forgotPassword}
                  >
                    Forgot password?
                  </Button>
                </Form.Group>
              </Form.Row>

              <Form.Text className="text-danger text-left pb-4">
                {errors.server || errors.emailorpassword ? (
                  <FiAlertTriangle className={classes.warningIcon} />
                ) : null}
                {errors.server}
                {errors.emailorpassword}
              </Form.Text>

              <Button
                type="submit"
                className="blueButton mt-0 mb-4 float-left"
                onClick={this.handleSubmit}
                disabled={disableLoginBtn}
              >
                {disableLoginBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    SIGN IN
                  </>
                ) : (
                  "SIGN IN"
                )}
              </Button>
            </Form>

            <br />
          </Card.Body>
        </Card>
      </div>
    ) : (
      <p className="p-5">Logging in...</p>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
