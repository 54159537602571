import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { countryList } from "../../../../config/constants";
import { getUsers } from "../../../../redux/actions/legalsActions";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";

const UserList = () => {
  const [state, setState] = useState({
    users: {},
    loadingUsers: true,
  });

  useEffect(() => {
    triggerLoadUsers();

    const userLoader = setInterval(triggerLoadUsers, 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, []);

  const triggerLoadUsers = () => {
    setState(prevState => {
      return {
        ...prevState,
        loadingUsers: true,
      };
    });
    getUsers("approved", 0)
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            users: res.data.users,
            loadingUsers: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const { users, loadingUsers } = state;

  const columns = [
    {
      dataField: "name",
      text: "First Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "surname",
      text: "Last Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userCountry",
      text: "Country",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "Time of Registration",
      sort: true,
    },
    {
      dataField: "profile",
      text: "",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  let data = [];
  if (Object.keys(users).length > 0) {
    for (let i = 0; i < users.length; i++) {
      // Some data formatting before displaying
      users[i].userCountry = countryList[users[i].country];

      if (Number.isInteger(users[i].timestamp)) {
        users[i].timestamp = moment(users[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      users[i].profile = (
        <Button
          type="button"
          variant="link"
          className="blueButton mt-0 text-white"
          as={Link}
          to={`/user/profile/${users[i]._id}`}
        >
          PROFILE
        </Button>
      );

      data.push(users[i]);
    }
  }

  // const rowStyle = (row, rowIndex) => {
  //   let style = {};
  //
  //   if (row.status === "approved") {
  //     style = { color: "#f00" };
  //   }
  //
  //   return style;
  // };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              Approved client accounts{" "}
              {loadingUsers ? <Spinner animation="border" /> : null}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              filter={filterFactory()}
              pagination={pagination}
              // rowStyle={rowStyle}
              bordered={false}
              headerClasses="tableHeaderClass"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserList;
