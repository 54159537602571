import React from "react";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { FiLogOut } from "react-icons/fi";

const navbar = props => {
  if (
    !props.auth.isAuthenticated ||
    window.location.href.includes("forgot-password") ||
    window.location.href.includes("resetpassword")
  ) {
    return null;
  }

  return (
    <Navbar expand="lg" variant="light">
      <span className="headerCategory ml-md-4">LEGALS</span>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {props.auth.user.name && props.auth.user.name.length > 0 ? (
          <>
            <span className="ml-auto headerCircle" />
            <div className="ml-2 mr-3 float-left">
              {props.auth.user.name} {props.auth.user.surname}
            </div>
            {/*<Dropdown className="mr-3 float-left">*/}
            {/*  <Dropdown.Toggle>*/}
            {/*    {props.auth.user.name} {props.auth.user.surname}*/}
            {/*  </Dropdown.Toggle>*/}

            {/*<Dropdown.Menu>*/}
            {/*  <Dropdown.Item*/}
            {/*    onSelect={() => (window.location.href = "/profile")}*/}
            {/*  >*/}
            {/*    Profile*/}
            {/*  </Dropdown.Item>*/}
            {/*  <Dropdown.Item*/}
            {/*    onSelect={() => (window.location.href = "/upload-documents")}*/}
            {/*  >*/}
            {/*    My documents*/}
            {/*  </Dropdown.Item>*/}
            {/*</Dropdown.Menu>*/}
            {/*</Dropdown>*/}
          </>
        ) : null}

        <span
          id="logoutBtn"
          onClick={props.logoutClicked}
          style={{ cursor: "pointer" }}
        >
          <FiLogOut />
        </span>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(navbar);
