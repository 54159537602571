import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import { getClaims } from "../../../../redux/actions/legalsActions";
import { numberFormat } from "../../../../utils/utils";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Card } from "react-bootstrap";
import { claimTypes } from "../../../../config/constants";
import { Link } from "react-router-dom";

const UserClaims = props => {
  const [users, setUsers] = useState({});
  const [userClaims, setUserClaims] = useState([]);

  useEffect(() => {
    getClaims("all", props.projectId, "all")
      .then(res => {
        setUsers(res.data.userList);
        setUserClaims(res.data.claims);
      })
      .catch(err => console.log(err));
  }, [props.projectId]);

  const columns = [
    {
      dataField: "timestamp",
      text: "DATE",
      sort: true,
    },
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "fraudTypeText",
      text: "FRAUD TYPE",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "estimatedLoss",
      text: "EST. LOSS (USD)",
      sort: true,
      classes: "text-right",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "action",
      text: "",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (row.status === "approved") {
      style = { color: "var(--color-green1)" };
    }
    if (row.status === "rejected") {
      style = { color: "var(--color-red1)" };
    }

    return style;
  };

  let data = [];
  if (!!userClaims) {
    for (let i = 0; i < userClaims.length; i++) {
      userClaims[i].fullName = users[userClaims[i].userId]
        ? `${users[userClaims[i].userId].name} ${
            users[userClaims[i].userId].surname
          }`
        : "";
      userClaims[i].email = users[userClaims[i].userId]
        ? users[userClaims[i].userId].email
        : "";

      userClaims[i].fraudTypeText = claimTypes[userClaims[i].fraudType];

      userClaims[i].estimatedLoss = numberFormat(
        userClaims[i].estimatedLoss,
        2,
        ".",
        ""
      );

      if (Number.isInteger(userClaims[i].timestamp)) {
        // Format date
        userClaims[i].timestamp = moment(userClaims[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      userClaims[i].action = (
        <Button
          as={Link}
          to={`/claim/edit/${userClaims[i]._id}`}
          className="orangeButton orangeButtonSmaller py-0 m-0"
        >
          VIEW
        </Button>
      );

      data.push(userClaims[i]);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0 pb-2">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            User Claims
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            rowStyle={rowStyle}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>
    </>
  );
};

UserClaims.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(UserClaims);
