import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getProjects, getClaim } from "../../../redux/actions/legalsActions";
import { getClaimTypesSelectListOptions } from "../../../utils/utils";
import { countryList } from "../../../config/constants";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import Addresses from "../../../components/Addresses/Addresses";
import FileIcons from "../../../components/FileIcons/FileIcons";
import { NotificationManager } from "react-notifications";

const Claims = props => {
  const [projectDetails, setProjectDetails] = useState({}); // all project fields get by projectId as a key
  const [projectNames, setProjectNames] = useState({}); // react-select values get by projectId as a key
  const [projectList, setProjectList] = useState([]); // react-select values to show in dropdown
  const [claim, setClaim] = useState(false);
  const [addressesTable, setAddressesTable] = useState([]);

  useEffect(() => {
    getClaim(props.match.params.claimId)
      .then(res => {
        if (res.data.success === false) {
          NotificationManager.error(res.data.error, "Error", 5000);
        } else {
          setClaim(res.data);
          if (
            res.data.projectAddresses &&
            res.data.projectAddresses.length > 0
          ) {
            setAddressesTable(res.data.projectAddresses);
          }
        }
      })
      .catch(err => console.log(err));

    loadProjects();
  }, []);

  const loadProjects = () => {
    getProjects("all", "all")
      .then(res => {
        let projects = [];
        let projectNames = {};
        let projectDetails = {};
        res.data.projects.forEach((value, index) => {
          projects.push({ value: value._id, label: value.projectName });
          projectNames[value._id] = {
            value: value._id,
            label: value.projectName,
          };
          projectDetails[value._id] = value;
        });
        setProjectList(projects);
        setProjectDetails(projectDetails);
        setProjectNames(projectNames);
      })
      .catch(err => console.log(err));
  };

  let data = {};
  if (claim) data = { ...claim };

  return (
    <>
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Claim
              <Button
                className="blueButton float-right"
                style={{ marginTop: 0 }}
                onClick={() => props.history.goBack()}
              >
                BACK
              </Button>
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4 py-3 no-gutters">
          <Col>
            <Form>
              {(data.status === "returned" || data.status === "rejected") &&
              data.comment &&
              data.comment.length > 0 ? (
                <Form.Group>
                  <Form.Label className="text-warning font-weight-bold">
                    Admin comment
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    maxLength="4000"
                    rows="5"
                    name="comment"
                    defaultValue={data.comment || ""}
                    disabled={true}
                  />
                </Form.Group>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">Fraud type</Form.Label>
                <Form.Control
                  as="select"
                  name="fraudType"
                  required
                  value={data.fraudType || ""}
                  disabled={true}
                >
                  <option value="">Choose Fraud type</option>
                  {getClaimTypesSelectListOptions()}
                </Form.Control>
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label className="font-weight-bold">
                    Project Name
                  </Form.Label>
                  <Select
                    name="projectId"
                    required
                    options={projectList}
                    value={{
                      label:
                        data.projectId && projectNames[data.projectId]
                          ? projectNames[data.projectId].label
                          : "Select project",
                      value: data.projectId || "",
                    }}
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#4B9EC9",
                      },
                    })}
                    className="text-dark"
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label style={{ width: "100%" }}>&nbsp;</Form.Label>
                  {data.projectId && data.projectId.length === 24 ? (
                    <Button
                      as={Link}
                      to={`/project/${data.projectId}`}
                      className="blueButton mt-0 mr-2"
                      style={{
                        float: "none",
                        width: "145px",
                        height: "38px",
                        lineHeight: "2",
                        fontWeight: "normal",
                      }}
                    >
                      Project Dashboard
                    </Button>
                  ) : null}
                </Form.Group>
              </Form.Row>

              {data.projectId ? (
                <Form.Row>
                  <Form.Group as={Col} md="6" xs="12">
                    <Form.Label className="font-weight-bold">
                      Project URL
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        projectDetails[data.projectId]
                          ? projectDetails[data.projectId].projectURL
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" xs="12">
                    <Form.Label className="font-weight-bold">
                      Country of origin
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        projectDetails[data.projectId]
                          ? countryList[projectDetails[data.projectId].country]
                          : "---"
                      }
                      disabled={true}
                    />
                  </Form.Group>
                </Form.Row>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">Addresses</Form.Label>

                <Addresses addresses={[...addressesTable]} disableEdit={true} />
              </Form.Group>

              {data.projectId ? (
                <>
                  <Form.Group>
                    <Form.Label className="font-weight-bold">
                      Project Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength="4000"
                      rows="5"
                      value={
                        projectDetails[data.projectId]
                          ? projectDetails[data.projectId].projectDescription
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="font-weight-bold">
                      Project Scam Description{" "}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength="4000"
                      rows="5"
                      value={
                        projectDetails[data.projectId]
                          ? projectDetails[data.projectId].scamDescription
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                </>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  User Scam Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="4000"
                  rows="5"
                  name="scamDescription"
                  placeholder="Enter scam description"
                  required
                  defaultValue={data.scamDescription || ""}
                  disabled={true}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Your estimated loss (USD)
                </Form.Label>
                <Form.Control
                  type="number"
                  name="estimatedLoss"
                  placeholder="Enter estimated loss in USD"
                  required
                  defaultValue={data.estimatedLoss || ""}
                  disabled={true}
                />
              </Form.Group>

              {data.files && data.files.length > 0 ? (
                <Form.Group>
                  <Form.Label className="font-weight-bold float-left">
                    Uploaded files:&nbsp;
                  </Form.Label>
                  <div className="d-flex justify-content-start">
                    {data.files.map((file, idx) => (
                      <FileIcons key={idx} file={file} />
                    ))}
                  </div>
                </Form.Group>
              ) : null}
            </Form>

            <Button
              className="blueButton float-right"
              style={{ marginTop: 0 }}
              onClick={() => props.history.goBack()}
            >
              BACK
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Claims.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Claims);
