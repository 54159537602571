import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import { getProject } from "../../../../redux/actions/legalsActions";
import { Button, Card } from "react-bootstrap";
import ClaimProject from "../../../../components/Modals/ClaimProject";

const ProjectData = props => {
  const [state, setState] = useState({
    showTakeProjectModal: false,
    disableBtn: false,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    getProject(props.projectId)
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, [props.projectId]);

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showTakeProjectModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showTakeProjectModal: false,
      };
    });
  };

  const projectReservationButton = () => {
    if (
      ["approved", "prereserved"].includes(data.status) &&
      !data.isReservedByMe &&
      props.auth.user.companyId
    ) {
      return (
        <Button
          variant="secondary"
          className="bigGreenButton py-1"
          onClick={handleShowModal}
        >
          I want to take this project
        </Button>
      );
    } else if (
      data.status === "reserved" &&
      data.companyId &&
      props.auth.user.companyId === data.companyId
    ) {
      return (
        <Button variant="secondary" className="bigGreenButton py-1" disabled>
          PROJECT TAKEN
        </Button>
      );
    } else if (data.isReservedByMe) {
      return (
        <Button variant="secondary" className="bigGreenButton py-1" disabled>
          PENDING APPROVAL
        </Button>
      );
    } else return "";
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "px-3 py-0 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Information
            {data ? projectReservationButton() : null}
          </p>

          {data ? (
            <>
              <p className="font-weight-bold mt-3 px-3">Project Name:</p>
              <p className="px-4">{data.projectName}</p>
              <p className="font-weight-bold mt-3 px-3">Project URL:</p>
              <p className="px-4">{data.projectURL}</p>
              <p className="font-weight-bold mt-3 px-3">Project Description:</p>
              <p className="px-4">{data.projectDescription}</p>
              <p className="font-weight-bold px-3">Project Scam Description:</p>
              <p className="px-4">{data.scamDescription}</p>
              <p className="font-weight-bold px-3">Comment:</p>
              <p className="px-4">{data.comment}</p>
            </>
          ) : null}
        </Card.Body>
      </Card>

      {state.showTakeProjectModal ? (
        <ClaimProject projectId={data._id} closeModal={handleCloseModal} />
      ) : null}
    </>
  );
};

ProjectData.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectData);
