import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProject } from "../../../../redux/actions/legalsActions";
import { Col, Container, Row, Button } from "react-bootstrap";
import ProjectBar from "./ProjectBar";
import Claimants from "./Claimants";
import ProjectData from "./ProjectData";
import ProjectLogs from "./ProjectLogs";
import ProjectUpdates from "./ProjectUpdates";
import UserClaims from "./UserClaims";
import { isMobileOnly } from "react-device-detect";
// import ClientLog from "./ClientLog";
// import Compliance from "./Compliance";

const ProjectDashboard = props => {
  const [project, setProject] = useState(false);

  useEffect(() => {
    getProject(props.match.params.id)
      .then(res => {
        setProject(res.data);
      })
      .catch(err => console.log(err));
  }, [props.match.params.id]);

  return (
    <Container fluid>
      {project ? (
        <Row>
          <Col>
            <ProjectBar projectData={project} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <Button
            className="blueButton float-right mb-3"
            style={{ marginTop: 0 }}
            onClick={() => props.history.goBack()}
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="8">
          <ProjectData projectId={props.match.params.id} />
          <ProjectUpdates projectId={props.match.params.id} project={project} />
          <UserClaims projectId={props.match.params.id} />
        </Col>
        <Col sm="12" md="4">
          <ProjectLogs projectId={props.match.params.id} />
          <Claimants projectId={props.match.params.id} />
          {isMobileOnly ? (
            <Button
              className="blueButton float-right mb-3"
              style={{ marginTop: 0 }}
              onClick={() => props.history.goBack()}
            >
              BACK
            </Button>
          ) : null}
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <Claims projectId={props.match.params.id} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Container>
  );
};

ProjectDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectDashboard);
